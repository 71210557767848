// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { DropdownOption, OptionGroup } from '../../internal/components/option/interfaces';
import { SelectProps } from '../interfaces';
import { useRef } from 'react';
import defaultOptionDescription from '../../internal/components/option/option-announcer';

interface UseAnnouncement {
  (inputProps: {
    announceSelected: boolean;
    highlightedOption?: DropdownOption;
    parentMap: Map<DropdownOption, DropdownOption>;
    selectedAriaLabel?: string;
    renderHighlightedAriaLive?: SelectProps.ContainingOptionAndGroupString;
  }): string;
}

export const useAnnouncement: UseAnnouncement = ({
  announceSelected,
  highlightedOption,
  parentMap,
  selectedAriaLabel,
  renderHighlightedAriaLive,
}) => {
  const prevAnnouncedGroup = useRef<DropdownOption | undefined>(undefined);
  let message = '';

  if (highlightedOption) {
    const group = parentMap.get(highlightedOption);
    const groupToAnnounce = group !== prevAnnouncedGroup.current ? group : undefined;
    prevAnnouncedGroup.current = group;
    message = (renderHighlightedAriaLive || defaultOptionDescription)(
      highlightedOption.option,
      groupToAnnounce?.option as OptionGroup
    );
    // prepend selectedAriaLabel, if custom `renderHighlightedAriaLive` was not specified
    if (announceSelected && !renderHighlightedAriaLive) {
      message = (selectedAriaLabel ? selectedAriaLabel + ' ' : '') + message;
    }
  }

  return message;
};
