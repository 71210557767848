// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { Dimensions, getOverflowParents } from '../../utils/scrollable-containers';

const AVAILABLE_SPACE_RESERVE = 50;

interface AvailableSpace {
  above: number;
  below: number;
  left: number;
  right: number;
}
export interface DropdownPosition {
  height: string;
  width: string;
  dropUp: boolean;
  dropLeft: boolean;
  left: string;
}
export interface InteriorDropdownPosition extends DropdownPosition {
  bottom: string;
  top: string;
}

const getClosestParentDimensions = (element: HTMLElement): any => {
  const parents = getOverflowParents(element).map(el => {
    const { height, width, top, left } = el.getBoundingClientRect();
    return {
      height,
      width,
      top,
      left,
    };
  });

  return parents.shift();
};

export const getAvailableSpace = (
  trigger: HTMLElement,
  dropdown: HTMLElement,
  overflowParents: ReadonlyArray<Dimensions>
): AvailableSpace => {
  const { bottom: triggerBottom, left: triggerLeft, right: triggerRight } = trigger.getBoundingClientRect();

  return overflowParents.reduce(
    ({ above, below, left, right }, overflowParent) => {
      const offsetTop = triggerBottom - overflowParent.top;
      const currentAbove = offsetTop - trigger.offsetHeight - AVAILABLE_SPACE_RESERVE;
      const currentBelow = overflowParent.height - offsetTop - AVAILABLE_SPACE_RESERVE;
      const currentLeft = triggerRight - overflowParent.left - AVAILABLE_SPACE_RESERVE;
      const currentRight = overflowParent.left + overflowParent.width - triggerLeft - AVAILABLE_SPACE_RESERVE;

      return {
        above: Math.min(above, currentAbove),
        below: Math.min(below, currentBelow),
        left: Math.min(left, currentLeft),
        right: Math.min(right, currentRight),
      };
    },
    { above: Number.MAX_VALUE, below: Number.MAX_VALUE, left: Number.MAX_VALUE, right: Number.MAX_VALUE }
  );
};

export const getInteriorAvailableSpace = (
  trigger: HTMLElement,
  dropdown: HTMLElement,
  overflowParents: ReadonlyArray<Dimensions>
): AvailableSpace => {
  const {
    bottom: triggerBottom,
    top: triggerTop,
    left: triggerLeft,
    right: triggerRight,
  } = trigger.getBoundingClientRect();

  return overflowParents.reduce(
    ({ above, below, left, right }, overflowParent) => {
      const currentAbove = triggerBottom - overflowParent.top - AVAILABLE_SPACE_RESERVE;
      const currentBelow = overflowParent.height - triggerTop + overflowParent.top - AVAILABLE_SPACE_RESERVE;
      const currentLeft = triggerLeft - overflowParent.left - AVAILABLE_SPACE_RESERVE;
      const currentRight = overflowParent.left + overflowParent.width - triggerRight - AVAILABLE_SPACE_RESERVE;

      return {
        above: Math.min(above, currentAbove),
        below: Math.min(below, currentBelow),
        left: Math.min(left, currentLeft),
        right: Math.min(right, currentRight),
      };
    },
    { above: Number.MAX_VALUE, below: Number.MAX_VALUE, left: Number.MAX_VALUE, right: Number.MAX_VALUE }
  );
};

export const getDropdownPosition = (
  trigger: HTMLElement,
  dropdown: HTMLElement,
  overflowParents: ReadonlyArray<Dimensions>,
  minWidth?: number,
  preferCenter = false
): DropdownPosition => {
  const availableSpace = getAvailableSpace(trigger, dropdown, overflowParents);
  const triggerWidth = trigger.getBoundingClientRect().width;
  minWidth = minWidth ? Math.min(triggerWidth, minWidth) : triggerWidth;
  const requiredWidth = dropdown.getBoundingClientRect().width;
  // dropdown should not be smaller than the trigger
  const idealWidth = Math.max(requiredWidth, minWidth);

  let dropLeft: boolean;
  let left: number | null = null;
  let width = idealWidth;

  //1. Can it be positioned with ideal width to the right?
  if (idealWidth <= availableSpace.right) {
    dropLeft = false;
    //2. Can it be positioned with ideal width to the left?
  } else if (idealWidth <= availableSpace.left) {
    dropLeft = true;
    //3. Fit into biggest available space either on left or right
  } else {
    dropLeft = availableSpace.left > availableSpace.right;
    width = Math.max(availableSpace.left, availableSpace.right, minWidth);
  }

  if (preferCenter) {
    const spillOver = (idealWidth - triggerWidth) / 2;

    // availableSpace always includes the trigger width, but we want to exclude that
    const availableOutsideLeft = availableSpace.left - triggerWidth;
    const availableOutsideRight = availableSpace.right - triggerWidth;

    const fitsInCenter = availableOutsideLeft >= spillOver && availableOutsideRight >= spillOver;
    if (fitsInCenter) {
      left = -spillOver;
    }
  }

  const dropUp = availableSpace.below < dropdown.offsetHeight && availableSpace.above > availableSpace.below;
  const availableHeight = dropUp ? availableSpace.above : availableSpace.below;
  // Try and crop the bottom item when all options can't be displayed, affordance for "there's more"
  const croppedHeight = Math.floor(availableHeight / 31) * 31 + 16;

  return {
    dropUp,
    dropLeft,
    left: left === null ? 'auto' : `${left}px`,
    height: `${croppedHeight}px`,
    width: `${width}px`,
  };
};

export const getInteriorDropdownPosition = (
  trigger: HTMLElement,
  dropdown: HTMLElement,
  overflowParents: ReadonlyArray<Dimensions>
): InteriorDropdownPosition => {
  const availableSpace = getInteriorAvailableSpace(trigger, dropdown, overflowParents);
  const { bottom: triggerBottom, top: triggerTop, width: triggerWidth } = trigger.getBoundingClientRect();
  const { top: parentDropdownTop, height: parentDropdownHeight } = getClosestParentDimensions(trigger);

  let dropLeft;

  let width = dropdown.getBoundingClientRect().width;
  const top = triggerTop - parentDropdownTop;
  if (width <= availableSpace.right) {
    dropLeft = false;
  } else if (width <= availableSpace.left) {
    dropLeft = true;
  } else {
    dropLeft = availableSpace.left > availableSpace.right;
    width = Math.max(availableSpace.left, availableSpace.right);
  }

  const left = dropLeft ? 0 - width : triggerWidth;

  const dropUp = availableSpace.below < dropdown.offsetHeight && availableSpace.above > availableSpace.below;
  const bottom = dropUp ? parentDropdownTop + parentDropdownHeight - triggerBottom : 0;
  const availableHeight = dropUp ? availableSpace.above : availableSpace.below;
  // Try and crop the bottom item when all options can't be displayed, affordance for "there's more"
  const croppedHeight = Math.floor(availableHeight / 31) * 31 + 16;

  return {
    dropUp,
    dropLeft,
    height: `${croppedHeight}px`,
    width: `${width}px`,
    top: `${top}px`,
    bottom: `${bottom}px`,
    left: `${left}px`,
  };
};
