// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import mergeRefs from 'react-merge-refs';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import OptionsList from '../../internal/components/options-list';
import { renderOptions } from '../utils/render-options';
import { useVirtual } from 'react-virtual';
import { SelectListProps } from './plain-list';
import { useContainerQuery } from '../../internal/hooks/container-queries';

import styles from './styles.css.js';

const VirtualList = (props: SelectListProps, ref: React.Ref<SelectListProps.SelectListRef>) => {
  return props.menuProps.open ? <VirtualListOpen {...props} ref={ref} /> : <VirtualListClosed {...props} ref={ref} />;
};

const VirtualListOpen = forwardRef(
  (
    {
      menuProps,
      getOptionProps,
      filteredOptions,
      filteringValue,
      isKeyboard,
      checkboxes,
      hasDropdownStatus,
      listBottom,
    }: SelectListProps,
    ref: React.Ref<SelectListProps.SelectListRef>
  ) => {
    // update component, when it gets wider or narrower to reposition items
    const [width, menuRef] = useContainerQuery(rect => rect.width, []);
    const { virtualItems, totalSize, scrollToIndex } = useVirtual({
      size: filteredOptions.length,
      parentRef: menuRef,
      // estimateSize is a dependency of measurements memo. We update it to force full recalculation
      // when the height of any option could have changed:
      // 1: because the component got resized (width property got updated)
      // 2: because the option changed its content (filteringValue property controls the highlight and the visibility of hidden tags)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      estimateSize: useCallback(() => 31, [width, filteringValue]),
      overscan: 5,
    });
    useImperativeHandle(
      ref,
      () => (index: number) => {
        if (isKeyboard) {
          scrollToIndex(index);
        }
      },
      [isKeyboard, scrollToIndex]
    );
    const finalOptions = renderOptions({
      options: virtualItems.map(({ index }) => filteredOptions[index]),
      getOptionProps,
      filteringValue,
      isKeyboard,
      checkboxes,
      hasDropdownStatus,
      virtualItems,
    });
    return (
      <OptionsList {...menuProps} ref={mergeRefs([menuRef, menuProps.ref])}>
        <div aria-hidden="true" key="total-size" className={styles['layout-strut']} style={{ height: totalSize }} />
        {finalOptions}
        {listBottom ? <li className={styles['list-bottom']}>{listBottom}</li> : null}
      </OptionsList>
    );
  }
);

const VirtualListClosed = forwardRef(
  ({ menuProps, listBottom }: SelectListProps, ref: React.Ref<SelectListProps.SelectListRef>) => {
    useImperativeHandle(ref, () => () => {}, []);
    return (
      <OptionsList {...menuProps} ref={menuProps.ref}>
        {listBottom ? <li className={styles['list-bottom']}>{listBottom}</li> : null}
      </OptionsList>
    );
  }
);

export default forwardRef(VirtualList);
