// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react';
import clsx from 'clsx';
import styles from './styles.css.js';
import { AppLayoutProps } from '../interfaces';
import { useStickyPosition } from '../utils/use-sticky-position';

interface NotificationsProps {
  testUtilsClassName: string;
  children?: React.ReactNode;
  labels: AppLayoutProps.Labels | undefined;
  topOffset: number | undefined;
  isRefresh: boolean;
  isMobile: boolean;
}

const StaticNotifications = ({ testUtilsClassName, children, labels, isRefresh, isMobile }: NotificationsProps) => {
  return (
    <div
      className={clsx(
        testUtilsClassName,
        isRefresh && styles['notifications-refresh'],
        isMobile && isRefresh && styles['notifications-refresh-mobile']
      )}
      role="region"
      aria-label={labels?.notifications}
    >
      {children}
    </div>
  );
};

const StickyNotifications = (props: NotificationsProps) => {
  const [stickyRef, placeholder] = useStickyPosition(props.topOffset);
  return (
    <>
      <div ref={stickyRef} className={styles['notifications-sticky']}>
        <StaticNotifications {...props} />
      </div>
      {placeholder}
    </>
  );
};

interface NotificationWrapperProps extends NotificationsProps {
  sticky: boolean | undefined;
  navigationPadding: boolean;
  toolsPadding: boolean;
  contentWidthStyles?: React.CSSProperties;
}

export const Notifications = React.forwardRef(
  (
    {
      contentWidthStyles,
      navigationPadding,
      toolsPadding,
      sticky,
      isRefresh,
      isMobile,
      ...rest
    }: NotificationWrapperProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const notificationsProps: NotificationsProps = { isRefresh, isMobile, ...rest };
    return (
      <div
        ref={ref}
        className={clsx(
          isRefresh && styles['root-refresh'],
          isMobile && styles['root-mobile'],
          !navigationPadding && styles['root-no-navigation-padding'],
          !toolsPadding && styles['root-no-tools-padding']
        )}
        style={isRefresh && !isMobile ? contentWidthStyles : undefined}
      >
        {sticky ? <StickyNotifications {...notificationsProps} /> : <StaticNotifications {...notificationsProps} />}
      </div>
    );
  }
);
