// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { DayIndex } from '../index';

function setDayIndex(date: Date, dayIndex: DayIndex): void {
  const diff = dayIndex - date.getDay();
  date.setDate(date.getDate() + diff);
}

export function renderDayName(locale: string, dayIndex: DayIndex): string {
  const tempDate = new Date();
  setDayIndex(tempDate, dayIndex);
  return tempDate.toLocaleDateString(locale, { weekday: 'short' });
}

export function renderMonthAndYear(locale: string, baseDate: Date): string {
  const result = baseDate.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
  });

  return result;
}

/*
 `toLocaleDateString` is expensive (10+ ms) to calculate in IE11.
*/
const dayLabelCache = new Map<string, string>();
export function renderDayLabel(locale: string, date: Date): string {
  const cacheKey = locale + date.getTime();
  const cachedValue = dayLabelCache.get(cacheKey);
  if (cachedValue) {
    return cachedValue;
  }

  const value = date.toLocaleDateString(locale, {
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  });
  dayLabelCache.set(cacheKey, value);
  return value;
}

export function getDateLabel(locale: string, date: Date | null): string | null {
  return date
    ? date.toLocaleDateString(locale, {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
    : null;
}
