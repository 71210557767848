// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { useMemo } from 'react';
import { generateUniqueId } from '../../../hooks/use-unique-id';

interface UseIds {
  (inputProps: { hasHighlightedOption: boolean; hasSelectedOption: boolean }): {
    highlightedOptionId?: string;
    selectedOptionId?: string;
    menuId: string;
  };
}

export const useIds: UseIds = ({ hasHighlightedOption, hasSelectedOption }) => {
  return {
    highlightedOptionId: useMemo(() => (hasHighlightedOption ? generateUniqueId() : undefined), [hasHighlightedOption]),
    selectedOptionId: useMemo(() => (hasSelectedOption ? generateUniqueId() : undefined), [hasSelectedOption]),
    menuId: useMemo(() => generateUniqueId(), []),
  };
};
