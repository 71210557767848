// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import styles from './styles.css.js';
import clsx from 'clsx';
import mergeRefs from 'react-merge-refs';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { fireNonCancelableEvent } from '../../events';
import { DropdownProps } from './interfaces';
import {
  getDropdownPosition,
  getInteriorDropdownPosition,
  DropdownPosition,
  InteriorDropdownPosition,
} from './dropdown-fit-handler';
import { Transition } from '../transition';
import { useVisualRefresh } from '../../hooks/use-visual-mode';
import { usePortalModeClasses } from '../../hooks/use-portal-mode-classes';
import { DropdownContextProvider, DropdownContextProviderProps } from './context';
import { getOverflowParentDimensions } from '../../utils/scrollable-containers';

interface DropdownContainerProps {
  children?: React.ReactNode;
  renderWithPortal?: boolean;
  id?: string;
  open?: boolean;
}

const DropdownContainer = ({ children, renderWithPortal = false, id, open }: DropdownContainerProps) => {
  if (renderWithPortal) {
    if (open) {
      return createPortal(<div id={id}>{children}</div>, document.body);
    } else {
      return null;
    }
  } else {
    return <>{children}</>;
  }
};

const Dropdown = ({
  children,
  trigger,
  open,
  onDropdownClose,
  onMouseDown,
  header,
  footer,
  dropdownId,
  stretchTriggerHeight = false,
  stretchWidth = true,
  stretchHeight = false,
  stretchToTriggerWidth = true,
  expandToViewport = false,
  preferCenter = false,
  interior = false,
  minWidth,
  hasContent = true,
  scrollable = true,
}: DropdownProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // This container is only needed to apply max-height to. We can't move max-height to it's parent
  // because of an IE11 issue with flexbox. https://github.com/philipwalton/flexbugs/issues/216
  const verticalContainerRef = useRef<HTMLDivElement>(null);
  // To keep track of the initial position (drop up/down) which is kept the same during fixed repositioning
  const fixedPosition = useRef<DropdownPosition | null>(null);

  const isRefresh = useVisualRefresh(triggerRef);

  const dropdownClasses = usePortalModeClasses(triggerRef);
  const [position, setPosition] = useState<DropdownContextProviderProps['position']>('bottom-right');

  const setDropdownPosition = (
    position: DropdownPosition | InteriorDropdownPosition,
    triggerBox: DOMRect,
    target: HTMLDivElement,
    verticalContainer: HTMLDivElement
  ) => {
    const entireWidth = !interior && stretchWidth;
    if (!stretchHeight) {
      if (!stretchWidth) {
        // 1px offset for dropdowns where the dropdown itself needs a border, rather than on the items
        verticalContainer.style.maxHeight = `${parseInt(position.height) + 1}px`;
      } else {
        verticalContainer.style.maxHeight = position.height;
      }
    }

    if (entireWidth && !expandToViewport) {
      if (stretchToTriggerWidth) {
        target.classList.add(styles['occupy-entire-width']);
      }
    } else {
      target.style.width = position.width;
    }
    // Using styles for main dropdown to adjust its position as preferred alternative
    if (position.dropUp && !interior) {
      target.classList.add(styles['dropdown-drop-up']);
      if (!expandToViewport) {
        target.style.bottom = '100%';
      }
    } else {
      target.classList.remove(styles['dropdown-drop-up']);
    }
    target.classList.add(position.dropLeft ? styles['dropdown-drop-left'] : styles['dropdown-drop-right']);

    if (position.left && position.left !== 'auto') {
      target.style.left = position.left;
    }

    // Position normal overflow dropdowns with fixed positioning relative to viewport
    if (expandToViewport && !interior) {
      target.style.position = 'fixed';
      if (position.dropUp) {
        target.style.bottom = `calc(100% - ${triggerBox.top}px)`;
      } else {
        target.style.top = `${triggerBox.bottom}px`;
      }
      if (position.dropLeft) {
        target.style.left = `calc(${triggerBox.right}px - ${position.width})`;
      } else {
        target.style.left = `${triggerBox.left}px`;
      }
      // Keep track of the initial dropdown position and direction.
      // Dropdown direction doesn't need to change as the user scrolls, just needs to stay attached to the trigger.
      fixedPosition.current = position;
      return;
    }

    // For an interior dropdown (the fly out) we need exact values for positioning
    // and classes are not enough
    // usage of relative position is impossible due to overwrite of overflow-x
    if (interior && isInteriorPosition(position)) {
      if (position.dropUp) {
        target.style.bottom = position.bottom;
      } else {
        target.style.top = position.top;
      }
      target.style.left = position.left;
    }

    if (position.dropUp && position.dropLeft) {
      setPosition('top-left');
    } else if (position.dropUp) {
      setPosition('top-right');
    } else if (position.dropLeft) {
      setPosition('bottom-left');
    } else {
      setPosition('bottom-right');
    }
  };

  useLayoutEffect(() => {
    if (open && dropdownRef.current && triggerRef.current && verticalContainerRef.current) {
      // cleaning previously assigned values,
      // so that they are not reused in case of screen resize and similar events
      verticalContainerRef.current.style.maxHeight = '';
      dropdownRef.current.style.width = '';
      dropdownRef.current.style.top = '';
      dropdownRef.current.style.bottom = '';
      dropdownRef.current.style.left = '';

      dropdownRef.current.classList.remove(styles['dropdown-drop-left']);
      dropdownRef.current.classList.remove(styles['dropdown-drop-right']);
      dropdownRef.current.classList.remove(styles['dropdown-drop-up']);
      // calculate scroll width only for dropdowns that has a scrollbar and ignore it for date picker components
      if (scrollable) {
        dropdownRef.current.classList.add(styles.nowrap);
      }

      const overflowParents = getOverflowParentDimensions(dropdownRef.current, interior, expandToViewport);
      const position = interior
        ? getInteriorDropdownPosition(triggerRef.current, dropdownRef.current, overflowParents)
        : getDropdownPosition(triggerRef.current, dropdownRef.current, overflowParents, minWidth, preferCenter);
      const triggerBox = triggerRef.current.getBoundingClientRect();
      setDropdownPosition(position, triggerBox, dropdownRef.current, verticalContainerRef.current);
      if (scrollable) {
        dropdownRef.current.classList.remove(styles.nowrap);
      }
    }
    // See AWSUI-13040
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, dropdownRef, triggerRef, verticalContainerRef, interior, stretchWidth]);

  // subscribe to outside click
  useEffect(() => {
    if (!open) {
      return;
    }
    const clickListener = (e: MouseEvent) => {
      if (!dropdownRef.current?.contains(e.target as Node)) {
        fireNonCancelableEvent(onDropdownClose);
      }
    };

    /*
     * This small delay allows the event that opened the dropdown to
     * finish bubbling, so that it is not immediately captured here.
     */
    const timeout = setTimeout(() => {
      window.addEventListener('click', clickListener);
    }, 0);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('click', clickListener);
    };
  }, [open, onDropdownClose]);

  // sync dropdown position on scroll and resize
  useLayoutEffect(() => {
    if (!expandToViewport || !open) {
      return;
    }
    const updateDropdownPosition = () => {
      if (triggerRef.current && dropdownRef.current && verticalContainerRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const target = dropdownRef.current;
        if (fixedPosition.current) {
          if (fixedPosition.current.dropUp) {
            dropdownRef.current.style.bottom = `calc(100% - ${triggerRect.top}px)`;
          } else {
            target.style.top = `${triggerRect.bottom}px`;
          }
          if (fixedPosition.current.dropLeft) {
            target.style.left = `calc(${triggerRect.right}px - ${fixedPosition.current.width})`;
          } else {
            target.style.left = `${triggerRect.left}px`;
          }
        }
      }
    };

    updateDropdownPosition();

    window.addEventListener('scroll', updateDropdownPosition, true);
    window.addEventListener('resize', updateDropdownPosition, true);
    return () => {
      window.removeEventListener('scroll', updateDropdownPosition, true);
      window.removeEventListener('resize', updateDropdownPosition, true);
    };
  }, [open, expandToViewport]);

  return (
    <div
      className={clsx(
        styles.root,
        interior && styles.interior,
        stretchTriggerHeight && styles['stretch-trigger-height']
      )}
    >
      <div className={clsx(stretchTriggerHeight && styles['stretch-trigger-height'])} ref={triggerRef}>
        {trigger}
      </div>
      <DropdownContainer renderWithPortal={expandToViewport && !interior} id={dropdownId} open={open}>
        <Transition in={open ?? false} exit={false}>
          {(state, ref) => (
            <div
              className={clsx(styles.dropdown, dropdownClasses, {
                [styles.open]: open,
                [styles['with-limited-width']]: !stretchWidth,
                [styles['hide-upper-border']]: stretchWidth,
                [styles.interior]: interior,
                [styles['is-empty']]: !header && !hasContent,
                [styles.refresh]: isRefresh,
                [styles['use-portal']]: expandToViewport && !interior,
              })}
              ref={mergeRefs([dropdownRef, ref])}
              data-open={open}
              data-animating={state !== 'exited'}
              onMouseDown={onMouseDown}
            >
              <div className={clsx(styles['dropdown-content-wrapper'], isRefresh && styles.refresh)}>
                <div className={styles['ie11-wrapper']}>
                  <div ref={verticalContainerRef} className={styles['dropdown-content']}>
                    <DropdownContextProvider position={position}>
                      {header}
                      {children}
                      {footer}
                    </DropdownContextProvider>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </DropdownContainer>
    </div>
  );
};

const isInteriorPosition = (
  position: DropdownPosition | InteriorDropdownPosition
): position is InteriorDropdownPosition => (position as InteriorDropdownPosition).bottom !== undefined;

export default Dropdown;
