// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react';
import useBaseComponent from '../internal/hooks/use-base-component';
import { applyDisplayName } from '../internal/utils/apply-display-name';
import { MultiselectProps } from './interfaces';
import InternalMultiselect from './internal';

export { MultiselectProps };

const Multiselect = React.forwardRef((props: MultiselectProps, ref: React.Ref<MultiselectProps.Ref>) => {
  const baseComponentProps = useBaseComponent('Multiselect');
  return <InternalMultiselect {...props} {...baseComponentProps} ref={ref} />;
});

applyDisplayName(Multiselect, 'Multiselect');
export default Multiselect;
