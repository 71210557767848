// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import clsx from 'clsx';
import React from 'react';
import { AppLayoutProps } from './interfaces';
import { Transition, TransitionStatus } from '../internal/components/transition';
import { DesktopDrawerProps, Drawer } from './drawer';
import styles from './styles.css.js';
import testutilStyles from './test-utils/styles.css.js';
import useContentHeight from './utils/use-content-height';

interface NavigationPanelProps {
  navigationOpen: boolean;
  navigationPanelTransitionState: TransitionStatus;
  isHidden: DesktopDrawerProps['isHidden'];

  navigationDrawerWidth: number;
  navigationWidth: number;
  headerHeight: DesktopDrawerProps['topOffset'];
  footerHeight: DesktopDrawerProps['bottomOffset'];
  panelHeightStyle: ReturnType<typeof useContentHeight>['panelHeightStyle'];

  navigation: React.ReactNode;

  ariaLabels: AppLayoutProps['ariaLabels'];

  isRefresh: boolean;
  isMobile: boolean;
  isMotionEnabled: boolean;

  setNavigationPanelTransitionState: React.Dispatch<React.SetStateAction<TransitionStatus>>;
  onNavigationToggle: DesktopDrawerProps['onToggle'];
  onClick: DesktopDrawerProps['onClick'];

  toggleRefs: DesktopDrawerProps['toggleRefs'];
}

export function NavigationPanel({
  ariaLabels,
  footerHeight,
  headerHeight,
  isHidden,
  isMobile,
  isMotionEnabled,
  isRefresh,
  navigation,
  navigationDrawerWidth,
  navigationWidth,
  navigationOpen,
  navigationPanelTransitionState,
  onClick,
  onNavigationToggle,
  panelHeightStyle,
  setNavigationPanelTransitionState,
  toggleRefs,
}: NavigationPanelProps) {
  const showNavigationPanelExitState =
    isRefresh &&
    isMotionEnabled &&
    ((!navigationOpen && navigationPanelTransitionState === 'exiting') ||
      (navigationOpen && (navigationPanelTransitionState === 'enter' || navigationPanelTransitionState === 'exited')));

  const transitioning = isMotionEnabled && ['exit', 'exiting'].indexOf(navigationPanelTransitionState) !== -1;
  return (
    <Transition in={navigationOpen} onStatusChange={setNavigationPanelTransitionState} disabled={!isRefresh}>
      {(_, navigationPanelTransitionRef) => (
        <div
          style={{
            width: navigationDrawerWidth,
          }}
        >
          <div
            ref={navigationPanelTransitionRef}
            className={clsx(
              styles['panel-wrapper-outer'],
              {
                [styles.mobile]: isMobile,
                [styles.open]: navigationOpen,
                [styles['navigation-panel-exit-state']]: showNavigationPanelExitState,
              },
              isRefresh && {
                [styles.refresh]: true,
                [styles['enable-motion']]:
                  isMotionEnabled &&
                  navigationPanelTransitionState !== 'enter' &&
                  navigationPanelTransitionState !== 'entered' &&
                  navigationPanelTransitionState !== 'exit' &&
                  navigationPanelTransitionState !== 'exited',

                [styles.hide]: showNavigationPanelExitState && !isHidden,
              }
            )}
            style={{
              ...(isMobile ? { top: headerHeight, bottom: footerHeight } : panelHeightStyle),
            }}
          >
            <Drawer
              type="navigation"
              visualRefresh={isRefresh}
              isMobile={isMobile}
              width={navigationWidth}
              isOpen={navigationOpen || transitioning}
              isHidden={isHidden}
              onToggle={onNavigationToggle}
              toggleRefs={toggleRefs}
              onClick={onClick}
              contentClassName={clsx(styles.navigation, testutilStyles.navigation)}
              closeClassName={clsx(styles['navigation-close'], testutilStyles['navigation-close'])}
              toggleClassName={clsx(styles['navigation-toggle'], testutilStyles['navigation-toggle'])}
              topOffset={isRefresh ? 0 : headerHeight}
              bottomOffset={isRefresh ? 0 : footerHeight}
              ariaLabels={ariaLabels}
            >
              {navigation}
            </Drawer>
          </div>
        </div>
      )}
    </Transition>
  );
}
